export default function CsharpIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      <linearGradient
        id="I7Vs8jY1D20h9XOqJ6Dbaa"
        x1="10.925"
        x2="26.209"
        y1="-11.924"
        y2="30.068"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#889097" />
        <stop offset="1" stopColor="#4c5963" />
      </linearGradient>
      <path
        fill="url(#I7Vs8jY1D20h9XOqJ6Dbaa)"
        fillRule="evenodd"
        d="M22.903,3.286c0.679-0.381,1.515-0.381,2.193,0c3.355,1.883,13.451,7.551,16.807,9.434
          C42.582,13.1,43,13.804,43,14.566c0,3.766,0,15.101,0,18.867c0,0.762-0.418,1.466-1.097,1.847
          c-3.355,1.883-13.451,7.551-16.807,9.434c-0.679,0.381-1.515,0.381-2.193,0c-3.355-1.883-13.451-7.551-16.807-9.434
          C5.418,34.899,5,34.196,5,33.434c0-3.766,0-15.101,0-18.867c0-0.762,0.418-1.466,1.097-1.847
          C9.451,10.837,19.549,5.169,22.903,3.286z"
        clipRule="evenodd"
      />
      <linearGradient
        id="I7Vs8jY1D20h9XOqJ6Dbab"
        x1="20.213"
        x2="36.727"
        y1="13.776"
        y2="59.147"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#889097" />
        <stop offset="1" stopColor="#4c5963" />
      </linearGradient>
      <path
        fill="url(#I7Vs8jY1D20h9XOqJ6Dbab)"
        fillRule="evenodd"
        d="M5.304,34.404C5.038,34.048,5,33.71,5,33.255c0-3.744,0-15.014,0-18.759c0-0.758,0.417-1.458,1.094-1.836
          c3.343-1.872,13.405-7.507,16.748-9.38c0.677-0.379,1.594-0.371,2.271,0.008c3.343,1.872,13.371,7.459,16.714,9.331
          c0.27,0.152,0.476,0.335,0.66,0.576L5.304,34.404z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M24,10c7.727,0,14,6.273,14,14s-6.273,14-14,14s-14-6.273-14-14S16.273,10,24,10z M24,17
          c3.863,0,7,3.136,7,7c0,3.863-3.137,7-7,7s-7-3.137-7-7C17,20.136,20.136,17,24,17z"
        clipRule="evenodd"
      />
      <linearGradient
        id="I7Vs8jY1D20h9XOqJ6Dbac"
        x1="27.238"
        x2="42.569"
        y1="2.443"
        y2="44.566"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#889097" />
        <stop offset="1" stopColor="#4c5963" />
      </linearGradient>
      <path
        fill="url(#I7Vs8jY1D20h9XOqJ6Dbac)"
        fillRule="evenodd"
        d="M42.485,13.205c0.516,0.483,0.506,1.211,0.506,1.784c0,3.795-0.032,14.589,0.009,18.384
          c0.004,0.396-0.127,0.813-0.323,1.127L23.593,24L42.485,13.205z"
        clipRule="evenodd"
      />
      <path
        d="M38,20v2h2v1h-2v2h2v1h-2v2h-1v-2h-2v2h-1v-2h-2v-1h2v-2h-2v-1h2v-2h1v2h2v-2H38 M35,25h2v-2h-2V25 M39,19h-1h-1h-1h-1h-1
          h-1v1v1h-1h-1v1v1v1v1v1v1h1h1v1v1h1h1h1v-1v-1v1v1h1h1h1v-1v-1h1h1v-1v-1v-1h-1h-1h1h1v-1v-1v-1h-1h-1v-1V19L39,19z"
        opacity=".05"
      />
      <path
        d="M38,20v2h2v1h-2v2h2v1h-2v2h-1v-2h-2v2h-1v-2h-2v-1h2v-2h-2v-1h2v-2h1v2h2v-2H38 M35,25h2v-2h-2V25 M38.5,19.5H38h-1h-0.5
          V20v1.5h-1V20v-0.5H35h-1h-0.5V20v1.5H32h-0.5V22v1v0.5H32h1.5v1H32h-0.5V25v1v0.5H32h1.5V28v0.5H34h1h0.5V28v-1.5h1V28v0.5H37h1
          h0.5V28v-1.5H40h0.5V26v-1v-0.5H40h-1.5v-1H40h0.5V23v-1v-0.5H40h-1.5V20V19.5L38.5,19.5z M35.5,23.5h1v1h-1V23.5L35.5,23.5z"
        opacity=".07"
      />
      <path
        fill="#fff"
        d="M40,23v-1h-2v-2h-1v2h-2v-2h-1v2h-2v1h2v2h-2v1h2v2h1v-2h2v2h1v-2h2v-1h-2v-2H40z M37,25h-2v-2h2V25z"
      />
      <g>
        <path
          d="M24,10c5.128,0,9.602,2.771,12.041,6.887l-6.073,3.47C28.737,18.347,26.527,17,24,17c-3.864,0-7,3.136-7,7
            c0,3.863,3.137,7,7,7c2.57,0,4.812-1.392,6.029-3.459l6.132,3.374C33.75,35.142,29.21,38,24,38c-7.727,0-14-6.273-14-14
            S16.273,10,24,10 M24,9C15.729,9,9,15.729,9,24s6.729,15,15,15c5.367,0,10.36-2.908,13.03-7.59l0.503-0.882l-0.89-0.49
            l-6.132-3.374l-0.851-0.468l-0.493,0.837C28.09,28.863,26.11,30,24,30c-3.308,0-6-2.692-6-6s2.692-6,6-6
            c2.099,0,4.011,1.076,5.115,2.879l0.507,0.828l0.842-0.481l6.073-3.47l0.882-0.504l-0.518-0.874C34.205,11.827,29.262,9,24,9L24,9z"
          opacity=".05"
        />
        <path
          d="M24,10c5.128,0,9.602,2.771,12.041,6.887l-6.073,3.47C28.737,18.347,26.527,17,24,17c-3.864,0-7,3.136-7,7
            c0,3.863,3.137,7,7,7c2.57,0,4.812-1.392,6.029-3.459l6.132,3.374C33.75,35.142,29.21,38,24,38c-7.727,0-14-6.273-14-14
            S16.273,10,24,10 M24,9.5C16.005,9.5,9.5,16.005,9.5,24S16.005,38.5,24,38.5c5.188,0,10.014-2.812,12.595-7.337l0.252-0.441
            l-0.445-0.245l-6.132-3.374l-0.425-0.234l-0.246,0.418C28.431,29.269,26.286,30.5,24,30.5c-3.584,0-6.5-2.916-6.5-6.5
            s2.916-6.5,6.5-6.5c2.275,0,4.346,1.166,5.542,3.118l0.253,0.414l0.421-0.241l6.073-3.47l0.441-0.252l-0.259-0.437
            C33.864,12.233,29.086,9.5,24,9.5L24,9.5z"
          opacity=".07"
        />
      </g>
    </svg>
  );
}
