import React from "react";

export default function TSIcon({ color = "#ffffff", size = "80" }) {
  return (
    <svg
      width={size}
      height={size}
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient
        id="O2zipXlwzZyOse8_3L2yya_wpZmKzk11AzJ_gr1"
        x1="15.189"
        x2="32.276"
        y1="-.208"
        y2="46.737"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2aa4f4"></stop>
        <stop offset="1" stopColor="#007ad9"></stop>
      </linearGradient>
      <rect
        width="36"
        height="36"
        x="6"
        y="6"
        fill="url(#O2zipXlwzZyOse8_3L2yya_wpZmKzk11AzJ_gr1)"
      ></rect>
      <polygon
        fill="#fff"
        points="27.49,22 14.227,22 14.227,25.264 18.984,25.264 18.984,40 22.753,40 22.753,25.264 27.49,25.264"
      ></polygon>
      <path
        fillRule="nonzero"
        clipRule="nonzero"
        d="M39.194,26.084c0,0-1.787-1.192-3.807-1.192s-2.747,0.96-2.747,1.986	c0,2.648,7.381,2.383,7.381,7.712c0,8.209-11.254,4.568-11.254,4.568V35.22c0,0,2.152,1.622,4.733,1.622s2.483-1.688,2.483-1.92	c0-2.449-7.315-2.449-7.315-7.878c0-7.381,10.658-4.469,10.658-4.469L39.194,26.084z"
        fill={color}
      />
    </svg>
  );
}
