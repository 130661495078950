import React from "react";
import { ABOUT_CONTENT } from "../Constants/constants";
import { motion } from "framer-motion";

const container = (delay) => ({
  hidden: { x: -70, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: delay },
  },
});

const About = () => {
  return (
    <div className="border-neutral-900 pb-d lg:mb-35 border-b">
      <div className="flex flex-wrap items-center justify-center">
        <div className="lg:w-1/2 w-full text-center">
          <div className="flex flex-col items-center justify-center">
            <motion.h1
              variants={container(0)}
              initial="hidden"
              animate="visible"
              className="lg:mt-16 lg:text-8xl pb-16 text-6xl font-thin tracking-tight"
            >
              Oz Hayun
            </motion.h1>
            <motion.span
              variants={container(0.2)}
              initial="hidden"
              animate="visible"
              className="bg-gradient-to-r from-pink-300 via-slate-500 to-purple-500 bg-clip-text text-3xl tracking-tight text-transparent"
            >
              Software Developer
            </motion.span>
            <motion.p
              variants={container(0.4)}
              initial="hidden"
              animate="visible"
              className="text-start max-w-3xl py-6 my-2 text-xl font-light tracking-tighter whitespace-pre-line"
            >
              {ABOUT_CONTENT}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
