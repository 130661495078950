import { EXPERIENCE_CONTENT } from "../Constants/constants";
import { motion } from "framer-motion";

export default function Experience() {
  return (
    <section id="experience">
      <div className="border-neutral-900 py-10 pb-4 border-b">
        <h1 className="my-12 text-4xl text-center">Experience</h1>
        {EXPERIENCE_CONTENT.map((experience, index) => (
          <motion.div
            whileInView={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
          >
            <div
              key={experience.role}
              className="lg:justify-center flex flex-wrap mb-8"
            >
              <div className="lg:w-1/4 w-full">
                <p className="text-neutral-400 mb-2 text-xl">
                  {experience.year}
                </p>
              </div>
              <div className="lg:w-3/4 w-full max-w-xl">
                <h6 className="text-neutral-400 mb-2 text-xl">
                  {experience.role} -{" "}
                  <span className="text-sm text-purple-100">
                    {experience.company}
                  </span>
                </h6>
                <p
                  className="text-neutral-400 mb-4 text-lg"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {experience.description}
                </p>
                {experience.technologies.map((tech, index) => (
                  <span
                    key={index}
                    className="bg-neutral-900 text-md px-2 py-1 mt-4 mr-2 font-medium text-purple-500 rounded"
                  >
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
}
