import React from "react";

export default function ProjectModal({ project, onClose }) {
  if (!project) return null;

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-75 p-4">
      <div className="bg-neutral-800 w-full max-w-2xl max-h-[80vh] overflow-y-auto p-6 mx-4 rounded-lg">
        <div className="flex items-start justify-between mb-4">
          <h2 className="text-2xl font-bold">{project.title}</h2>
          <button
            onClick={onClose}
            className="text-neutral-400 hover:text-white"
          >
            ✕
          </button>
        </div>
        <a
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
          className="block cursor-pointer"
        >
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-[200px] lg:h-[300px] object-contain rounded-lg mb-4 hover:opacity-90 transition-opacity"
          />
        </a>
        <p className="text-neutral-300 mb-4">{project.description}</p>
        <div className="flex flex-wrap gap-2">
          {project.technologies.map((tech, index) => (
            <span
              key={index}
              className="bg-neutral-900 px-2 py-1 text-sm font-medium text-purple-500 rounded"
            >
              {tech}
            </span>
          ))}
        </div>
        <a
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:bg-purple-700 inline-block px-4 py-2 mt-4 transition-colors bg-purple-600 rounded-lg"
        >
          View Project
        </a>
      </div>
    </div>
  );
}
