import { RiReactjsLine } from "react-icons/ri";
// import { AiOutlineJavaScript } from "react-icons/ai";
// import { AiOutlinePython } from "react-icons/ai";
// import { AiOutlineDotNet } from "react-icons/ai";
import { FaNodeJs } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";

// import { TbBrandTypescript } from "react-icons/tb";
// import { TbBrandCpp } from "react-icons/tb";
import CIcon from "./Icons/CIcon";
import CssIcon from "./Icons/CssIcon";
import Html5Icon from "./Icons/Html5Icon";
import JavaIcon from "./Icons/JavaIcon";
import CsharpIcon from "./Icons/CsharpIcon";
// import NextJsIcon from "./Icons/NextJsIcon";
import TSIcon from "./Icons/TSIcon";
import PythonIcon from "./Icons/PythonIcon";
import CppIcon from "./Icons/CppIcon";

export default function Skills() {
  return (
    <section id="skills">
      <div className="border-neutral-800 pb-14 container flex flex-col items-center px-4 py-10 mx-auto border-b">
        <h1 className="my-12 text-4xl text-center">Skills</h1>
        <div className="flex flex-wrap items-center justify-center w-full md:max-w-[60%] h-full gap-2 md:gap-4">
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <RiReactjsLine className="text-7xl text-cyan-400 w-20 h-20" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <FaNodeJs className="text-7xl w-20 h-20 text-yellow-500" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <SiMongodb className="text-7xl w-20 h-20 text-green-500" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <Html5Icon className="text-7xl w-20 h-20 text-orange-500" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <CssIcon className="text-7xl w-20 h-20" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <TSIcon className=" text-7xl text-cyan-600 w-20 h-20" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            {/* <AiOutlinePython className="text-7xl w-20 h-20 text-yellow-500" /> */}
            <PythonIcon className="text-7xl w-20 h-20" />
          </div>

          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <CIcon className="text-7xl w-20 h-20 text-[#065798]" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <CppIcon className="text-7xl w-20 h-20" />
          </div>
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            {/* <AiOutlineDotNet className="text-7xl w-20 h-20 text-white" /> */}
            <CsharpIcon className="text-7xl w-20 h-20 text-white" />
          </div>
          {/* <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <CsharpIcon className="text-7xl w-20 h-20 text-purple-600" />
          </div> */}
          <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <JavaIcon className="text-7xl w-20 h-20 text-red-500" />
          </div>

          {/* <div className="rounded-2xl border-neutral-800 p-4 border-4">
            <NextJsIcon className="text-7xl w-30 h-30 text-white" />
          </div> */}
          {/* <div className="rounded-2xl border-neutral-800 items flex items-center justify-center p-4 border-4">
            <TSIcon className="text-7xl w-20 h-20 text-white" />
          </div> */}
        </div>
      </div>
    </section>
  );
}
