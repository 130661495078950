import React, { memo } from "react";

// Mobile view component
const MobileView = memo(({ projects, onProjectClick }) => (
  <div className="flex flex-col gap-6 px-4 py-2">
    {projects.map((project, index) => (
      <div
        key={index}
        className="w-full cursor-pointer"
        onClick={() => onProjectClick(project)}
      >
        <div className="bg-neutral-800 hover:scale-105 p-4 transition-all duration-300 rounded-lg shadow-xl">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-[200px] object-contain rounded-lg mb-4"
          />
          <h3 className="text-xl font-semibold text-center">{project.title}</h3>
        </div>
      </div>
    ))}
  </div>
));

// Desktop carousel view
const CarouselView = memo(
  ({ projects, currentRotation, radius, angleStep, onProjectClick }) => {
    const getProjectPosition = (angle) => {
      const absoluteAngle = (((currentRotation + angle) % 360) + 360) % 360;
      const zIndex =
        absoluteAngle > 180
          ? projects.length - Math.round(absoluteAngle / angleStep)
          : Math.round((180 - absoluteAngle) / angleStep);

      return {
        transform: `rotateY(${angle}deg) translateZ(${radius}px)`,
        zIndex,
      };
    };

    return (
      <div
        className="project-carousel"
        style={{
          transform: `translate(-50%, -50%) perspective(2000px) rotateX(-10deg) rotateY(${currentRotation}deg)`,
        }}
      >
        {projects.map((project, index) => {
          const angle = index * angleStep;
          const styles = getProjectPosition(angle);

          return (
            <div
              key={index}
              className="carousel-item"
              style={styles}
              onClick={() => onProjectClick(project)}
            >
              <div className="project-card bg-neutral-800 hover:scale-105 p-4 transition-all duration-300 rounded-lg shadow-xl">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-[120px] object-contain rounded-lg mb-4"
                />
                <h3 className="line-clamp-1 text-lg font-semibold text-center">
                  {project.title}
                </h3>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);

const ProjectSlider = ({
  projects,
  isAutoRotating,
  currentRotation,
  radius,
  angleStep,
  onProjectClick,
}) => {
  return (
    <>
      <div className="lg:block hidden">
        <CarouselView
          projects={projects}
          currentRotation={currentRotation}
          radius={radius}
          angleStep={angleStep}
          onProjectClick={onProjectClick}
        />
      </div>
      <div className="block lg:hidden">
        <MobileView projects={projects} onProjectClick={onProjectClick} />
      </div>
    </>
  );
};

export default memo(ProjectSlider);
