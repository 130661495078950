import { EDUCATION } from "../Constants/constants";
import { motion } from "framer-motion";

export default function Education() {
  return (
    <section id="education">
      <div className="border-neutral-900 py-10 pb-4 border-b">
        <h1 className="my-12 text-4xl text-center">Education</h1>
        {EDUCATION.map((education, index) => (
          <motion.div
            whileInView={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
          >
            <div
              key={education.degree}
              className="lg:justify-center flex flex-wrap mb-8"
            >
              <div className="lg:w-1/4 w-full">
                <p className="text-neutral-400 mb-2 text-xl">
                  {education.year}
                </p>
              </div>
              <div className="lg:w-3/4 w-full max-w-xl">
                <h6 className="text-neutral-400 mb-2 text-xl">
                  {education.institution} -{" "}
                  <span className="text-xl text-purple-100">
                    {education.degree}
                  </span>
                </h6>
                <p
                  className="text-neutral-400 mb-4 text-lg"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {education.description}
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
}
