import React from "react";
import { FaPlay, FaPause } from "react-icons/fa";

export default function SliderController({
  onPrevious,
  onNext,
  onPlayPause,
  isAutoRotating,
  isDisabled,
}) {
  return (
    <div className="absolute bottom-0 left-1/2 flex gap-4 mt-20 mb-4 -translate-x-1/2">
      <button
        onClick={onPrevious}
        className="hover:bg-purple-700 w-28 px-4 py-2 transition-colors bg-purple-600 rounded-lg"
      >
        Previous
      </button>
      <button
        onClick={onPlayPause}
        className="hover:bg-purple-700 w-28 flex items-center justify-center px-4 py-2 transition-colors bg-purple-600 rounded-lg"
        disabled={isDisabled}
      >
        {isAutoRotating ? (
          <FaPause className="text-lg" />
        ) : (
          <FaPlay className="text-lg" />
        )}
      </button>
      <button
        onClick={onNext}
        className="hover:bg-purple-700 w-28 px-4 py-2 transition-colors bg-purple-600 rounded-lg"
      >
        Next
      </button>
    </div>
  );
}
