import { PROJECTS } from "../../Constants/constants";
import { useState, useEffect, useRef } from "react";
import ProjectModal from "./ProjectModal.jsx";
import ProjectSlider from "./ProjectSlider";
import SliderController from "./SliderController";

// Calculate these values once, outside the component
const projectCount = PROJECTS.length;
const angleStep = 360 / projectCount;
const rotationSpeed = 0.2;

export default function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentRotation, setCurrentRotation] = useState(0);
  const [isAutoRotating, setIsAutoRotating] = useState(true);
  const animationRef = useRef();
  const radius = 450;

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  const rotateCarousel = (direction) => {
    setIsAutoRotating(false);
    const step = direction === "right" ? -angleStep : angleStep;
    setCurrentRotation((prev) => prev + step);
  };

  // Only run animation on desktop
  useEffect(() => {
    const isDesktop = window.matchMedia("(min-width: 1024px)").matches;
    if (!isDesktop) {
      setIsAutoRotating(false);
      return;
    }

    if (!isAutoRotating || selectedProject) {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      return;
    }

    let startTime = performance.now();
    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      setCurrentRotation((prev) => prev - (rotationSpeed * elapsed) / 16.67);
      startTime = currentTime;
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isAutoRotating, selectedProject]);

  // Stop auto-rotation when modal is open
  useEffect(() => {
    if (selectedProject) {
      setIsAutoRotating(false);
    }
  }, [selectedProject]);

  return (
    <section id="projects">
      <div className="border-neutral-900 py-10 pb-4 border-b">
        <h1 className="my-12 text-4xl text-center">Projects</h1>

        <div className="project-carousel-container">
          <ProjectSlider
            projects={PROJECTS}
            isAutoRotating={isAutoRotating}
            currentRotation={currentRotation}
            radius={radius}
            angleStep={angleStep}
            onProjectClick={setSelectedProject}
          />

          <div className="lg:block hidden">
            <SliderController
              onPrevious={() => rotateCarousel("left")}
              onNext={() => rotateCarousel("right")}
              onPlayPause={() => setIsAutoRotating(!isAutoRotating)}
              isAutoRotating={isAutoRotating}
              isDisabled={selectedProject !== null}
            />
          </div>
        </div>

        <ProjectModal project={selectedProject} onClose={handleCloseModal} />
      </div>
    </section>
  );
}
