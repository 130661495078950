import project1 from "../Assets/projects/project-1.png";
import project2 from "../Assets/projects/project-2.png";
import project3 from "../Assets/projects/project-3.png";
import project4 from "../Assets/projects/project-4.png";

export const ABOUT_CONTENT = `I am a Computer Science graduate from The Academic College of Tel-Aviv, Yafo, 
    with a strong background in software development and automation engineering. 
    My experience includes frontend development, test automation, and infrastructure design.

    During my service in the IDF, I developed and maintained automation frameworks, 
    mentored junior team members, and worked closely with cross-functional teams 
    to improve system reliability. 

    I enjoy problem-solving, learning new technologies, and collaborating with others 
    to build high-quality software. My goal is to create impactful and well-crafted solutions 
    while continuously growing as a developer.
`;

export const EDUCATION = [
    {
        year: "2020 - 2024",
        institution: "The Academic College of Tel-Aviv, Yafo",
        degree: "B.Sc. in Computer Science",
        description: "Graduated with a solid foundation in software development, algorithms, data structures, and a strong focus on practical applications."
    },
    {
        year: "2021",
        institution: "Result Academy",
        degree: "NLP - Practitioner",
        description: `Learned techniques to help people navigate and overcome life's challenges, aiding their personal development.
Acquired valuable skills in understanding and altering behavioral patterns, which improved my ability to communicate effectively and empathize with others.
The course emphasized tailoring strategies to suit personal requirements, sparking engagement and aiding in significant improvement.`
    },
    {
        year: "2016",
        institution: "IDF - BASMACH",
        degree: "Quality Assurance Course",
        description: "Completed a comprehensive course in Quality Assurance, laying the groundwork for my later work in both manual and automated testing."
    },
    {
        year: "2013 - 2016",
        institution: "Hadarim High School",
        degree: "High School Diploma",
        description: "Graduated with a focus on science and technology."
    },
];

export const EXPERIENCE_CONTENT = [
    {
        year: "09/2024 - Present",
        role: "Frontend Developer",
        company: "TinyTap",
        description: `Developing and maintaining interactive web applications using modern frontend technologies, ensuring a seamless user experience.
Collaborating with product and design teams to enhance platform features and optimize performance.
Implementing best practices for accessibility, responsiveness, and state management in a large-scale Next.js application.
Refactoring and improving existing code for better maintainability and efficiency.`,
        technologies: ["React", "JavaScript", "TypeScript", "Next.js"],
    },
    {
        year: "07 - 09/2024",
        role: "Automation Engineer",
        company: "TinyTap",
        description: `Designed and implemented automated test frameworks to improve testing coverage and efficiency.
Developed and maintained end-to-end UI tests using Selenium and Python.
Integrated automation into CI/CD pipelines with Jenkins, ensuring smooth and reliable deployments.
Worked with MongoDB to validate test data and improve testing reliability across environments.`,
        technologies: ["Python", "Selenium", "MongoDB", "Jenkins"],
    },
    {
        year: "2017 - 2019",
        role: "Automation Engineer",
        company: "IDF",
        description: `Designed and implemented comprehensive end-to-end automation frameworks and test cases using Java, enhancing testing efficiency and coverage.

Conducted in-depth analyses of specification documents to develop precise test scenarios, facilitating seamless integration of system functionalities.
Collaborated closely with developers and system analysts to ensure alignment with Agile methodologies.

Provided mentorship to junior team members, imparting knowledge on best practices in automation and the effective use of testing tools.`,
        technologies: ["Java", "Selenium", "LeanFT"],
    },
    {
        year: "2018",
        role: "Quality Assurance Instructor",
        company: "IDF - BASMACH",
        description: `Delivered a comprehensive software testing course, focusing on both manual and automated testing techniques, to 30 individuals from the Orthodox community, preparing them for roles across various IDF units.

Navigated the unique challenges of this role with a strong emphasis on teamwork, professionalism, and the ability to perform effectively under pressure.

Fostered a learning environment that encouraged creativity and critical thinking, while setting a high standard through personal conduct and commitment.`,
        technologies: ["Java", "Selenium", "SQL"],
    },
    {
        year: "2017",
        role: "Manual Quality Assurance",
        company: "IDF - MATZPAN",
        description: `Led the creation and running of test cases to thoroughly check the system and find any issues.

Worked closely with developers and system analysts to make sure testing plans matched what the system was supposed to do.

The job needed careful attention, the ability to solve problems, and good teamwork and communication to make sure our products were the best they could be.`,
        technologies: ["TFS", "MTM", "MongoDB"],
    },
];

export const PROJECTS = [
    {
        title: "Adventures in the Hospital",
        link: "https://asafharofewebapp1.web.app/",
        image: project1,
        description:
            `"Adventures in the Hospital" is specifically tailored to the process a child undergoes in the pediatric emergency department.
            The text found in pop-up messages throughout the game is written in a way that makes the processes in the emergency room accessible to the child in their own language. This helps to reduce anxiety and stress.`,
        technologies: ["JavaScript", "React", "CSS", "Firebase"],
    },
    {
        title: "CodeBlocks",
        link: "https://codeblocksharing.netlify.app/",
        image: project2,
        description:
            "A platform designed to facilitate code sharing between students and teachers, enhancing the educational experience by allowing real-time collaboration and feedback.",
        technologies: ["Node.js", "JavaScript", "React", "CSS", "MongoDB"],
    },
    {
        title: "Pomodoro Timer & Task Tracker",
        link: "https://italian-tomato.netlify.app/",
        image: project3,
        description:
            "A productivity tool that combines a Pomodoro timer with a task tracking system. Users can manage their work sessions, breaks, and tasks efficiently. The app helps improve focus and time management skills by following the Pomodoro Technique.",
        technologies: ["React", "JavaScript", "CSS"],
    },
    {
        title: "Mine Sweeper Game",
        link: "https://minesweepe.netlify.app/",
        image: project4,
        description:
            "A thrilling puzzle game where you'll strategically reveal cells and use numerical clues to avoid hidden mines, challenging your logic and deduction skills in customizable grid sizes from beginner to expert levels.",
        technologies: ["React", "JavaScript", "CSS"],
    },
];