import React from "react";
import { Link } from "react-scroll";
import logo from "../Assets/oz-hayun.png";
import { FaLinkedin, FaGithub, FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  //   const offsetValue = isOpen ? 20 : -90;
  const offsetValue = window.innerWidth >= 1024 ? -80 : -20;

  return (
    <nav className="bg-gradient-to-b from-slate-900 via-indigo-900 to-purple-800 fixed top-0 left-0 z-50 w-full py-2">
      <div className="container px-4 mx-auto">
        <div className="flex items-center justify-between">
          <Link
            to="about"
            smooth={true}
            duration={500}
            className="flex-shrink-0 cursor-pointer"
          >
            <img src={logo} alt="logo" className="w-auto h-16" />
          </Link>

          <div className="flex items-center space-x-4 text-xl">
            <a href="https://www.linkedin.com/in/ozhayun/">
              <FaLinkedin />
            </a>
            <a href="https://github.com/ozhayun">
              <FaGithub />
            </a>
            <div
              className="lg:hidden text-2xl cursor-pointer"
              onClick={toggleMenu}
            >
              {isOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
        </div>
        <div
          className={`flex-col lg:flex-row lg:flex justify-center items-center mt-2 lg:mt-0 ${
            isOpen ? "flex" : "hidden"
          } lg:flex lg:space-x-4 text-base`}
        >
          <ul className="lg:flex-row lg:space-y-0 lg:space-x-4 flex flex-col space-y-2 text-base">
            <li>
              <Link
                to="about"
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="hover:font-bold cursor-pointer"
                onClick={toggleMenu}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="experience"
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="hover:font-bold cursor-pointer"
                onClick={toggleMenu}
              >
                Experience
              </Link>
            </li>
            <li>
              <Link
                to="education"
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="hover:font-bold cursor-pointer"
                onClick={toggleMenu}
              >
                Education
              </Link>
            </li>
            <li>
              <Link
                to="projects"
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="hover:font-bold cursor-pointer"
                onClick={toggleMenu}
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="skills"
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="hover:font-bold cursor-pointer"
                onClick={toggleMenu}
              >
                Skills
              </Link>
            </li>
            <li>
              <Link
                to="contact-me"
                smooth={true}
                offset={offsetValue}
                duration={500}
                className="hover:font-bold cursor-pointer"
                onClick={toggleMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
